<!--
View for user login
-->
<template>
  <div>
    <div>
        <div class="row justify-content-center align-items-center">
            <div class="col-md-6">
                <div class="col-md-12">
                    <h3 class="text-center">Login</h3>
                    <label for="login-email">Email:</label><br>
                    <input @keyup="onUserEnter" v-bind:class="{ loginIncorrect: isLoginIncorrect }" v-model="email" type="text" id="login-email" class="form-control">
                    <label for="login-password">Password:</label><br>
                    <input ref="passwordInput" @keyup="onPasswortEnter" v-bind:class="{ loginIncorrect: isLoginIncorrect }" v-model="password" type="password" id="login-password" class="form-control">
                    <span>Remember me</span> <span><input v-model="useLocalStorage" type="checkbox"></span><br/>
                    <input type="button" v-on:click="tryLogin" class="btn btn-secondary" value="Login">
                    <div class="text-right">
                        <a class="nav-link" v-on:click="register" href="#">Registrieren</a>
                    </div>
                    <div class="text-right">
                        <a class="nav-link" v-on:click="forgotPassword" href="#">Passwort vergessen</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>
<style>
  input.loginIncorrect {
    background-color: #ffcccc;
  }
</style>
<script>
import SrvAuthentification from '../global/SrvAuthentification';

export default {
    data: function() {
        return {
            email: '',
            password: '',
            isLoginIncorrect: false,
            useLocalStorage: false // flag for "remember me"
        };
    },
    created: function() {
    },
    methods: {
        tryLogin: function () {
            const that = this;
            SrvAuthentification.login(this.email, this.password, this.useLocalStorage)
            .then(function(bearerToken) {
                if (bearerToken) {
                    that.$router.push('/search').catch(function(){});
                }
                else
                    that.isLoginIncorrect = true;
            })
            .catch(function() {
                that.isLoginIncorrect = true;
            });
        },
        // the bearerToken may be stored within sessionStorage or localStorage (i.e. after F5 or after setting "remember me")
        // then, a new login is not necessary, but we must reload the userdata, which are lost after F5...
        register: function () {
            this.$router.push('/register').catch(function(){});
        },
        forgotPassword: function () {
            this.$router.push('/forgotPassword').catch(function(){});
        },
        onUserEnter: function(evt) {
            if (evt.key=='Enter')
                this.$refs.passwordInput.focus();
        },
        onPasswortEnter: function(evt) {
            if (evt.key=='Enter')
                this.tryLogin();
        }
    }
}               
</script>

