<!--
View for registration of a new user
-->
<template>
    <div class="register">
        <div class="row justify-content-center align-items-center">
            <div class="col-md-6">
                <div class="col-md-12">
                    <h3 class="text-center">Register</h3>
                    <label for="register-user-name">User name:</label><br>
                    <input placeholder="unique user name" v-model="userName" type="text" id="register-user-name" class="form-control">
                    <b-alert v-if="userNameError" show variant="danger">{{userNameError}}</b-alert>
                    <label for="register-email">Email:</label><br>
                    <input placeholder="email" v-model="email" type="text" id="register-email" class="form-control">
                    <b-alert v-if="emailError" show variant="danger">{{emailError}}</b-alert>
                    <label for="register-password">Password:</label><br>
                    <input placeholder="password at least 8 characters" v-model="password" type="password" id="register-password" class="form-control">
                    <b-alert v-if="passwordError" show variant="danger">{{passwordError}}</b-alert>
                    <label for="register-password-confirm">Confirm Password:</label><br>
                    <input placeholder="confirm password" v-model="passwordConfirm" type="password" id="register-password-confirm" class="form-control">
                    <b-alert v-if="passwordConfirmError" show variant="danger">{{passwordConfirmError}}</b-alert>
                    <input type="button" v-on:click="register" class="btn btn-secondary" value="Register">
                </div>
            </div>
        </div>
    </div>
</template>
<style>
</style>
<script>

import SrvServerAccess from '../global/SrvServerAccess';
import SrvMessages from '../global/SrvMessages';

export default {
    data: function() {
        return {
            userName: '',
            userNameError: '',
            email: '',
            emailError: '',
            password: '',
            passwordError: '',
            passwordConfirm: '',
            passwordConfirmError: ''
        };
    },  
    methods: {
        register: function () {
            this.userNameError = '';
            this.emailError = '';
            this.passwordError = '';
            this.passwordConfirmError = '';
            if (! this.userName)
                this.userNameError = 'Bitte einen Benutzernamen angeben';
            if (! this.email)
                this.emailError = 'Bitte eine gültige Email-Adresse angeben';
            if (! this.password)
                this.passwordError = 'Bitte ein Passwort angeben';
            if (this.password!=this.passwordConfirm)
                this.passwordConfirmError = 'Die beiden Passwörter stimmen nicht überein';
            if (this.userNameError || this.emailError || this.passwordError || this.passwordConfirmeError)
                return;
            const that = this;
            SrvServerAccess.request('POST','/api/register', {
                    email: this.email,
                    name: this.userName,
                    password: this.password
            }).then(
                // Success
                function(serverResponse) {
                    if (serverResponse && serverResponse.data && serverResponse.data.message)
                        SrvMessages.info(serverResponse.data.message);
                    else
                        SrvMessages.info('Account wurde angelegt. Benutzerrechte bekommen Sie demnächst zugeteilt.');
                    setTimeout(function(){
                        that.$router.push('/login').catch(function(){});                        
                    },11000);
                },
                // Error
                function(error) {
                    if (  error.response.data && error.response.data.errors 
                        &&(  error.response.data.errors.name
                           ||error.response.data.errors.email
                           ||error.response.data.errors.password)) {
                        if (error.response.data.errors.name instanceof Array)
                            that.userNameError = error.response.data.errors.name.join('\n');
                        if (error.response.data.errors.email instanceof Array)
                            that.emailError = error.response.data.errors.email.join('\n');
                        if (error.response.data.errors.password instanceof Array)
                              that.passwordError = error.response.data.errors.password.join('\n');
                    } else {
                        let message = error;
                        while(message && (typeof(message)=='object'))
                            for(let key in message) {
                                message = message[key];
                                break;
                            }
                        SrvMessages.error(message);
                    }
                }
            );
        }
    }
}               
</script>

