<!--
Control bar with search input fields
-->
<template>
    <div class="container w-100 searchDashboard small">
      <div class="row w-100">
        <div class="searchDashboardTime">
            <span class="searchDashboardLabel">Datum</span>
            <cmp-ctrl-date-picker :fromDate="fromDate" :toDate="toDate" min="1910-01-01"
                       max="1969-12-31" v-on:datechanged="dateRangeChanged"
                       @enter="search"></cmp-ctrl-date-picker>
        </div>
        <span class="searchDashboardSearchMode">Suche in<br/>
            <span v-if="searchMode=='event'">Tagungen</span>
            <span v-if="searchMode=='text'">Texten</span>
        </span>
        <div class="radio text-left searchDashboardSearchAlgorithm border">
            <input type="radio" id="searchDashboardSearchAlgorithmExact" v-bind:checked="searchAlgorithm=='exact'" value="exact" v-on:input="setSearchAlgorithm" name="searchDashboardSearchAlgorithm">
            <label for="searchDashboardSearchAlgorithm">exakt</label>
            <br/>
            <input type="radio" id="searchDashboardSearchAlgorithmIntelligent" v-bind:checked="searchAlgorithm=='intelligent'" value="intelligent" v-on:input="setSearchAlgorithm" name="searchDashboardSearchAlgorithm">
            <label for="searchDashboardSearchAlgorithm">intelligent</label>
        </div>
        <div class="searchDashboardExtendedSearchSwich border">
            <span class="searchDashboardLabel">erweiterte Suche</span>
            <cmp-ctrl-slider-checkbox v-model="showExtendedSearch"></cmp-ctrl-slider-checkbox>

        </div>
        <div class="searchDashboardKeywordsLabel">
          <span class="searchDashboardLabel">Stichworte</span>
        </div>
        <div class="searchDashboardKeywords">
          <multiselect v-model="selectedKeywords" :options="allKeywords" :multiple="true" :close-on-select="false"
             placeholder="Stichworte" label="keyword" track-by="id" class="small"></multiselect>
        </div>
        <div class="searchDashboardSearch">
          <button @click="search" class="btn btn-secondary float-end">suchen</button>
        </div>
      </div>
      <div class="row searchDashboardSearchFrame">
          <span class="searchDashboardLabel">suche im Titel</span>
          <input placeholder="suche im Titel" v-model="searchStringTitle" @keyup="searchKeyUp" list="titleList" :title="titleTooltip" />
          <datalist id="titleList">
              <option v-for="item in dataListeForTitles" :value="item.title" />
          </datalist>
      </div>
      <div class="row searchDashboardSearchFrame">
          <span class="searchDashboardLabel">suche im Volltext</span>
          <input placeholder="suche im Volltext" v-model="searchStringFulltext" @keyup="searchKeyUp" />
      </div>
      <div class="row searchDashboardSearchFrame">
          <span v-if="showExtendedSearch" class="searchDashboardLabel">erweiterte Suche</span>
          <input v-if="showExtendedSearch" placeholder="erweiterte Suche" id="searchDashboardSearchExtendedInput" v-model="searchStringExtended" @keyup="searchKeyUp" />
          <div id="searchDashboardEditEventIcon">
              <span v-dir-permission="'g_EDITORS'" v-on:click="addRecord">&#x2795;</span>
              <span v-dir-permission="'g_EDITORS'" v-on:click="addRecord">Event</span>
          </div>
      </div>
    </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
    .searchDashboard {
        background-color: #ffffff;
        margin-bottom: 5px;
    }
    .searchDashboardLabel {
        font-size:0.7em;
        font-weight:700;
        margin-right:10px;
    }
    .searchDashboardTime {
        margin-right:30px;
    }
    .searchDashboardSearchFrame {
        position: relative;        
        width:100%;
        height:23px;
    }
    .searchDashboardSearchFrame span {
        position: absolute;
        left: 0px;
        height:23px;
    }
    .searchDashboardSearchFrame input {
        position: absolute;
        left: 100px;
        height:23px;
        right: 0px;
    }
    #searchDashboardSearchExtendedInput {
        right: 100px;
    }
    #searchDashboardEditEventIcon {
        position: absolute;
        right: 0px;
    }
    #searchDashboardEditEventIcon span {
        position: relative;
        cursor: pointer;
    }
    .searchDashboardKeywordsLabel {
        margin-left:30px;
    }
    .searchDashboardKeywords {
        margin-left:0px;
    }
    .searchDashboardSearch {
        margin-left:30px;
    }
    span.searchDashboardSearchMode {
        font-size:0.8em;
    }
    div.searchDashboardSearchAlgorithm {
        font-size:0.8em;
        margin-left: 8px;
    }
    div.searchDashboardSearchAlgorithm input {
        accent-color: #777777;
        margin-bottom: -10px;
    }
    div.searchDashboardSearchAlgorithm label {
        margin-bottom: -10px;
    }
    span.multiselect__tag {
        background-color:#007bff;
    }
    span.multiselect__option--highlight {
        background-color:#007bff;
    }
    div.searchDashboardExtendedSearchSwich {
        margin-left: 10px;
    }
</style>
<script>
import CmpCtrlDatePicker from './CmpCtrlDatePicker.vue';
import CmpCtrlSliderCheckbox from '../../controls/CmpCtrlSliderCheckbox';
import Multiselect from 'vue-multiselect'; // https://vue-multiselect.js.org/#sub-getting-started
import SrvServerAccess from '../../global/SrvServerAccess';
import SrvMessages from '../../global/SrvMessages';
import DirPermission from '../../controls/DirPermission';

export default {
    props: [
        'preset'   // {fromDate:...,toDate:...,...]  um von aussen Werte zu setzen
        // emit  add-event     nach Druecken auf +
    ],
    data: function() {
        return {
            fromDate: '1910-01-01',
            toDate: '1968-12-31',
            searchStringFulltext: '',
            searchStringTitle: '',
            searchStringExtended: '',
            searchAlgorithm: 'exact', // // one of 'exact' or 'intelligent'
            selectedKeywords: [],
            allKeywords: [], //  [{keyword:...,id:...}]
            dataListeForTitles: [], // [{event_id:...,title:...}]
            titleTooltip: '',
            limit: 10,          // hits per page
            showExtendedSearch: false  // flag for extended search textfield
        }
    },
    components: {
        cmpCtrlDatePicker: CmpCtrlDatePicker,
        cmpCtrlSliderCheckbox: CmpCtrlSliderCheckbox,
        multiselect: Multiselect
    },
    computed: {
        searchMode: function() { // one of 'event' or 'text'
            return this.searchStringFulltext ? 'text' : 'event';
        }
    },
    watch: {
        searchStringTitle: function() {
            this.loadTitles();
        },
        preset: function(newPreset) {
            this.fromDate = newPreset.fromDate ? newPreset.fromDate : '';
            this.toDate = newPreset.toDate ? newPreset.toDate : '';
            this.searchStringFulltext = newPreset.searchStringFulltext ? newPreset.searchStringFulltext : '';
            this.searchStringTitle = newPreset.searchStringTitle ? newPreset.searchStringTitle : '';
            this.searchStringExtended = newPreset.searchStringExtended ? newPreset.searchStringExtended : '';
            this.searchAlgorithm = newPreset.searchAlgorithm ? newPreset.searchAlgorithm : '';
/*
            this.selectedKeywords = newPreset.selectedKeywords ? newPreset.selectedKeywords : [];
            this.allKeywords = newPreset.allKeywords ? newPreset.allKeywords : [];
            this.dataListeForTitles = newPreset.dataListeForTitles ? newPreset.dataListeForTitles : [];
            this.titleTooltip = newPreset.titleTooltip ? newPreset.titleTooltip : '';
            this.limit = newPreset.limit ? newPreset.limit : '';
*/
        }
    },
    mounted: function() {
        this.loadKeywords();
    },
    methods: {
        search: function() {
            const keywords = [];
            for (let i=0; i<this.selectedKeywords.length; i++)
                keywords.push(this.selectedKeywords[i].id);
            this.$emit('search', {
                fromDate: this.fromDate,
                toDate: this.toDate,
                searchStringFulltext: this.searchStringFulltext,
                searchStringTitle: this.searchStringTitle,
                searchStringExtended: this.searchStringExtended,
                keywordIds: keywords,
                searchAlgorithm: this.searchAlgorithm,
                limit: (this.limit && !isNaN(this.limit)) ? this.limit : 10
            });
        },
        searchKeyUp: function(evt) {
            if (evt.key=='Enter')
                this.search();
        },
        fromDateChanged: function() {
            if (this.fromDate>this.toDate)
                this.toDate = this.fromDate;
        },
        toDateChanged: function() {
            if (this.fromDate>this.toDate)
                this.fromDate = this.toDate;
        },
        dateRangeChanged: function(fromTo) {
            this.fromDate = fromTo.from;
            this.toDate   = fromTo.to;
        },
        setSearchAlgorithm: function(evt) {
             this.searchAlgorithm = evt.target.value;
        },
        loadKeywords: function() {
            const that = this;
            SrvServerAccess.request('POST','/api/GetKeywords', {category: 'all'}).then(
                function(serverData) {
                    // Format of serverData
                    // {data:{
                    //   {keywords:[
                    //     {id:...,
                    //      key_id:...,
                    //      key_keyword:...,
                    //      key_category:...,
                    //      key_lang:...
                    //   },{...}]}
                    // } }
                    const keywords = serverData.data.keywords;
                    for (let i=0; i<keywords.length; i++)
                        that.allKeywords.push({keyword: keywords[i].key_keyword, id:keywords[i].key_id});
                },            
                function(error) {
                    // maybe SessionToken is got invalid
                    if (error && error.response && (error.response.status==401))
alert('TODO CmpSearchDashboard - Session abgelaufen: das sollte im Router abgefangen werden');
                    // other errors, i.e. no rights
                    else if (error && error.response && error.response.data) {
                        const errortext = error.response.data.error ? error.response.data.error : 'something got wrong';
                        SrvMessages.error(errortext);
                    }
                    that.isLoading = false;
                }
            );
        },
        loadTitles: function() {
            if (this.searchStringTitle.length<3) {
                this.dataListeForTitles = [];
                this.titleTooltip = '';
                return;
            }
            const that = this;
            SrvServerAccess.request('POST','/api/titleGet', {s_title: this.searchStringTitle}).then(
                function(serverData) {
                    // Format of serverData
                    // {data:{count: 33, titles: [{event_id:...,title:...},{...}]}}
                    that.dataListeForTitles = serverData.data.titles;
                    that.titleTooltip = serverData.data.titles.length+' von '+serverData.data.count+' Titeln';
                },            
                function(error) {
                    that.dataListeForTitles = [];
                    that.titleTooltip = '';
                }
            );
        },
        addRecord: function() {
            this.$emit('add-event');
        }
    },
    directives: {
        DirPermission: DirPermission
    }
}
</script>
