<!--
An area with some controls and a top bar with language icons
in order to select the language for the controls.
-->
<template>
  <div class="ctrlTranslation">
    <div class="ctrlTranslationFlags">
      <ctrl-language-icon v-for="(record,lang) in translationData"
        :lang = "lang"
        :semi-transparent = "lang!=selectedLanguage"
        :clickable = "true"
        v-on:click="selectedLanguage=lang"
      ></ctrl-language-icon>
      <span class="ctrlTranslationEditArea" v-dir-permission="'g_EDITORS'">
        &#x270e;
        <ctrl-language-icon v-for="(lang) in ['deu','eng','spa','hun']"
          :lang = "lang"
          :clickable = "true"
          :semi-transparent = "!translationData[lang]"
          :title="mouseOverTitle4lang(lang)"
          v-on:click="click4edit(lang)"
        ></ctrl-language-icon>
      </span>
    </div>
    <slot :lang="selectedLanguage">
    </slot>
  </div>    
</template>
<style>
    div.ctrlTranslation {
        border: 1px solid #aaaaaa;
    }
    div.ctrlTranslationFlags {
        display: flex;
    }
    span.ctrlTranslationEditArea {
        margin-left: 30px;
    }
</style>
<script>

import CtrlLanguageIcon from '@/controls/CtrlLanguageIcon.vue'
import DirPermission from './DirPermission';

export default {
    props: [
        'translation-data',     // {deu:{key1:configData1, key2:configData2, ...}, eng:{...}, ...}
        // emit(edit, {lang:...,id:...}) auf eine vorhandene Sprache wurde zum Editieren geklickt (id=TranslationId)
        // emit(add, lang)      eine weitere Sprache soll hinzugefuegt werden
    ],
    data: function() {
        return  {
            selectedLanguage: ''
        };
    },
    computed: {
    },
    created: function() {
        this.selectFirstLanguage();
    },
    watch: {
        translationData: function(newVal) {
            this.selectFirstLanguage();
        }
    },
    methods: {
        selectFirstLanguage: function() {
            for (const lang in this.translationData) {
                this.selectedLanguage = lang;
                break;
            }
        },
        click4edit: function(lang) {
            if (this.translationData[lang])
                this.$emit('edit', {lang: lang, id:this.translationData[lang].id});
            else
                this.$emit('add', lang);
        },
        mouseOverTitle4lang: function(lang) {
            if (this.translationData[lang])
                return 'edit language '+lang;
            else
                return 'add language '+lang;
        }
    },
    components: {
        CtrlLanguageIcon: CtrlLanguageIcon
    },
    directives: {
        DirPermission: DirPermission
    }
}
</script>
